.card {
    min-height: 100px;
}

.text1 {
    font-size: 120%;
}

.text2 {
    font-size: 100%;
}

.text3 {
    font-size: 90%;
}
.selectedNeed {
    background-color: #d4ebf2 !important;
}
.selectedFeeling {
    background-color: #f2dbd4 !important;
}
.hidden {
    display: none !important;
}
.nav-link.active {
    font-weight: 500;
}

.tab.active {
    border-bottom: 2px solid !important;
    border-radius: 0 !important;
}

th, td {
    padding-right: 1em;
}

/*When the modal fills the screen it has an even 2.5% on top and bottom*/
/*Centers the modal*/
.modal-dialog {
    margin: 2.5vh auto;
}

/*Sets the maximum height of the entire modal to 95% of the screen height*/
.modal-content {
    max-height: 95vh;
    overflow: auto;
}

/*Sets the maximum height of the modal body to 90% of the screen height*/
.modal-body {
    max-height: 90vh;
}
/*Sets the maximum height of the modal image to 69% of the screen height*/
.modal-body img {
    max-height: 69vh;
}


/* Navbar */
@media (min-width: 375px) {
    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }
}

@media (min-width: 375px) {
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x);
    }
}

/* Dragula */
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
}
.gu-hide {
    display: none !important;
}
.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}
.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

